




























































































import { Component, Prop } from "vue-property-decorator";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { mixins } from "vue-class-component";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import { AUTH_STORE_NAME, AuthStoreActions } from "@/store/auth.store";
import { namespace } from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import { UserType } from "@/enum/UserType.enum";
import { UserRoles } from "@/enum/UserRoles.enum";
import { passwordCriteria } from "@/misc/PasswortCriterias";
import User from "@/models/User.model";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { APPLICATION_STORE_NAME } from "@/store/application.store";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    IconTooltip: IconTooltipComponent,
    DuplicatesList: () => import("@/components/Duplicates.list.component.vue"),
  },
  mixins: [validationMixin],
  validations: {
    user: {
      firstName: { required },
      lastName: { required },
      email: { required }
    },
  }
})
export default class EditNewUserComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  @Prop({ default: User.parseFromObject({}) })
  public userTemp!: User;

  public user: User = User.parseFromObject({});

  @AuthStore.Action(AuthStoreActions.PUT_USER)
  private updateUser!: (payload: { user: User }) => Promise< User | null>;

  private showDuplicatedList: boolean = false;

  private showDuplicationInfo: boolean = true;

  private onHideDuplicationInfo() {
    this.showDuplicationInfo = false;
  }
  private onShowDuplicatedList() {
    this.showDuplicatedList = true;
  }


  public created(){
    this.user = {...this.userTemp} as User;
  }

  private slots = [UserRoles.USER, UserRoles.EMPLOYEE, UserRoles.ADMIN];

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async changeUserInfo() {
    // Form is Valid try to Register a new Account
    try {
      this.$v.$touch();

      // Form is Invalid return here
      if (this.$v.$invalid) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
        return;
      }

      // Set Loading to true
      this.isLoading = true;
      // logs in the user, checks if the user has the correct role
      await this.updateUser({ user: this.user });
      this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.USER_UPDATED");
      this.dismiss();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 412:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.FAILED_PAYMENT_METHOD");
            break;
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit("closeDialog", reload);
  }
}
